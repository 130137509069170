import React from 'react';

import Grid from "@material-ui/core/Grid";

import video from "./assets/video.mp4";
import image from "./assets/image.jpg";

import './header.style.scss';

function Header() {

    return (
      <div className="header">
        <div className="bg">
          <video className="bg__content" autoPlay muted loop>
            <source src={video} type="video/mp4" />A video was suppose to play
            on the background. But it looks like your browser isn't supported.
          </video>
        </div>

        <Grid container className="header__content">
          <Grid
            item
            xs={12}
            md={4}
            className="center"
            style={{ filter: "drop-shadow(3px 3px 5px rgba(0,0,0,0.5))" }}
          >
            <figure className="header__shape">
              <img className="header__img" alt="Inhoo Ham" src={image} />
            </figure>
          </Grid>

          <Grid item xs={12} md={8}>
            <h4 className="header__title anim-typewriter">Hi! I'm James.</h4>
            <p className="header__wording">
              I'm a developer based in New York City.
            </p>
          </Grid>
        </Grid>
      </div>
    );
}

export default Header;