import React from "react";

import Grid from "@material-ui/core/Grid";

import "./portfolio.style.scss";
import Card from "./card.jsx";

import pencilUpIcon from "./assets/pencilUpIcon.png";
import pencilUpSS from "./assets/pencilUpSS.png";
import escapeIcon from "./assets/escapeIcon.gif";
import stalkerSS from "./assets/stalkerSS.jpg";
import paperPixelIcon from "./assets/paperPixelIcon.png";
import paperPxSS from "./assets/paperPxSS.png";
import nobbosSS from "./assets/nobbosSS.png";
import ftpxSS from "./assets/ftpxSS.jpg";
import ftpxIcon from "./assets/ftpxIcon2.png";
import building from "./assets/building.png";
import nobosIcon from "./assets/nobosIcon.gif";

function Portfolio() {
  return (
    <Grid container className="portfolio__content">
      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={pencilUpSS}
          link="https://www.pencilup.com/#/books/5/chapters/16"
          icon={pencilUpIcon}
          animate="astronaut"
          title="Pencil Up"
          description="Freewriting collaboration space."
          tech="Rails &sdot; React &sdot; Postgres	&sdot; AWS	&sdot; Firebase"
        />
      </Grid>

      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={paperPxSS}
          link="https://play.google.com/store/apps/details?id=com.WingBeans.PaperPixel&fbclid=IwAR1KnyxxsczgJtnymgy1VZF1rn8bt8Rnu_80AX_DyY0UiyAlQtLzegB-r-4"
          icon={paperPixelIcon}
          animate="plane"
          title="Paper Pixel"
          description="Hyper casual game to plant trees."
          tech="Unity	&sdot; C#"
        />
      </Grid>

      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={stalkerSS}
          link="https://jamsuham22.github.io/escape/"
          icon={escapeIcon}
          animate="tom"
          title="Stalker"
          description="Escape room inpired JavaScript Canvas game."
          tech="JavaScript &sdot; Canvas"
        />
      </Grid>

      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={nobbosSS}
          link="https://nobbos.herokuapp.com/"
          icon={nobosIcon}
          animate="snacks"
          title="nobbos V1"
          description="Retail shelf rental for all brands."
          tech="Rails &sdot; React &sdot; Postgres	&sdot; Auth	&sdot; AWS "
        />
      </Grid>

      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={ftpxSS}
          link="https://fortytwopx.herokuapp.com/"
          icon={ftpxIcon}
          animate="logo"
          title="42px"
          description="Single page clone of 500px."
          tech="Rails &sdot; React &sdot; Postgres	&sdot; Auth	&sdot; Cloudinary"
        />
      </Grid>

      <Grid item xs={6} md={4} className="portfolio__item center">
        <Card
          background={building}
          link="https://www.inhooham.com/"
          icon={building}
          animate=""
          title="e-Commerce Website"
          description="Work in progress."
          tech="React &sdot; Node	&sdot; Stripe &sdot; AWS &sdot; Firebase"
        />
      </Grid>
    </Grid>
  );
}

export default Portfolio;