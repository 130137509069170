import React from "react";

import "./story.style.scss";

function Story() {
  return (
    <div className="story">
      <div className="story__header">Recommendations</div>
      <div className="story__content">
        <div className="story__quote">
          James is a fastidious developer who goes above and beyond in every
          deliverable he's had at Myxx. He has a great understanding of the
          business and is able to connect his work directly to business
          outcomes, as well as being able to easily voice his concerns to
          stakeholders as they arise. James' well-rounded experience is
          incredibly valuable and I would wholeheartedly recommend him for
          anyone looking for an engineer who can bridge the gap between business
          and engineering easily and effortlessly.
        </div>
        <div className="story__author">Chris B. CTO</div>
      </div>
      <div className="story__content">
        <div className="story__quote">
          James strives for solutions that satisfy both engineering rigor, as
          well as user needs. He takes critical user feedback with a lens of
          opportunity which is needed in a startup environment. James is a team
          player through and through, and will excel anywhere his work has an
          impact. I highly recommend James.
        </div>
        <div className="story__author">Monica W. CEO</div>
      </div>
      <div className="story__content">
        <div className="story__quote">
          As a coworker and mentor, James has been absolutely indispensable both
          for myself and our organization as a whole. James is an incredibly
          knowledgable and capable developer and will work harder than anyone I
          know to get the job done. During my time with him, James has
          consistently delivered at a high level on countless projects, made
          numerous improvements and best-practice implementations, and (lucky
          for me) has been more than willing to share his expertise with me
          every step of the way. I would highly recommend James to anyone who is
          looking for a top-tier engineer who is easy to work with, extremely
          knowledgeable, and holds himself to an extremely high standard.
        </div>
        <div className="story__author">Alex N. Software Engineer</div>
      </div>
    </div>
  );
}

export default Story;
