import React from 'react';

import './portfolio.style.scss';


const Card = props => {
  return (
    <div className="card">
      <div className="card-front">
        <img
          alt={props.animate}
          className={`card-front-icon ${props.animate}`}
          src={props.icon}
        />
        <div className="card-front-title">
          {props.title}
        </div>
        <div className="card-front-desc">
          {props.description}
        </div>
        <div className="card-front-tech">
          {props.tech}
        </div>
      </div>
      <div className="card-back">
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <img
            alt={props.animate}
            className="card-back-bg"
            src={props.background}
          />
        </a>
      </div>
    </div>
  );
}

export default Card;
