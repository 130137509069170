import React from 'react';

import "./form.style.scss";

function Form() {
  return (
    <div className="form-section center">
      <div className="form-section-header">Contact Me</div>
      <form
        className="form"
        method="post"
        action="https://formspree.io/inhooham90@gmail.com"
      >
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Your Name"
          className="form-input"
        />
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Your Email"
          className="form-input"
        />

        <input
          type="text"
          name="subject"
          id="subject"
          placeholder="Subject"
          className="form-input"
        />

        <textarea
          name="message"
          id="message"
          placeholder="Message"
          className="form-textarea"
        />

        <div>
          <input type="reset" value="Clear Form" className="form-button clear" />
          <input type="submit" value="Send Message" className="form-button send" />
        </div>
      </form>
    </div>
  );
}

export default Form;