import React from 'react';

import './App.css';

import Header from './component/header/header.component.jsx';
import Story from './component/story/story.component.jsx';
import Skillset from './component/skillset/skillset.component.jsx';
import Portfolio from "./component/portfolio/portfolio.component.jsx";
import Form from "./component/form/form.component.jsx";
import Footer from "./component/footer/footer.component.jsx";

function App() {
  return (
    <div>
      <Header />
      <Skillset />
      <Story />
      <Portfolio />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
