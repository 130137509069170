import React from "react";

import "./footer.style.scss";

const Footer = () => {

    return (
      <div className="footer">
        <div className="footer__group">
          <div>Designed and created by me, In Hoo James Ham &copy;</div>
          <div className="footer__links">
            <a
              href="https://www.linkedin.com/in/james-inhoo-ham-824982108/"
              className="footer__link"
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/jamsuham22"
              className="footer__link"
            >
              GitHub
            </a>
            <a
              href="https://angel.co/u/inhoo-j-ham"
              className="footer__link"
            >
              Angel List
            </a>
          </div>
        </div>
      </div>
    );
};

export default Footer;