import React from 'react';

import Grid from "@material-ui/core/Grid";

import "./skillset.style.scss";

function Skillset() {
  return (
    <div className="skillset">
      {/* <div className="skillset__scale-container">
        <div className="skillset__scale">

        </div>
      </div> */}
      <Grid container className="skillset__content">
        <Grid item xs={6} md={2} className="skillset__item center">
          React
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          Redux
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          JavaScript
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          Rails
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          Ruby
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          JQuery
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          C#
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          Unity
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          HTML
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          CSS
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          SASS
        </Grid>
        <Grid item xs={6} md={2} className="skillset__item center">
          SQL
        </Grid>
      </Grid>
    </div>
  );
}

export default Skillset;